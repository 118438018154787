import React from 'react'

import { makeBtStyles } from '../Theme'
import PromoImage, { usePromoImage } from '../components/PromoImage'
import GiftWrapPanel from '../components/GiftWrapPanel'
import { MIN_TABLET_SIZE, MIN_DESKTOP_SIZE } from '../utils'

import MainCard from './MainCard'
import TrackingCard from './TrackingCard'
import SmsCard from './SmsCard'
import ShipmentActivity from './ShipmentActivity'

export const MAIN_COL_WIDTH = 330
export const SECONDARY_COL_WIDTH = 570
export const THIRD_COL_WIDTH = 300
export const COL_SPACING = 15

const CONTENT_WIDTH = 1280
const CONTENT_HEIGHT = 817
const MOBILE_CONTENT_WIDTH = 95

const SHORT_PROMO_IMAGE_HEIGHT = 250
const PROMO_IMAGE_TALL_TYPE = 'tall'

const usePromoStyles = makeBtStyles(theme => ({
  canvas: {
    borderRadius: '10px',
  },
}))

const useTrackingPageStyles = makeBtStyles(theme => ({
  shipmentActivity: {
    [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
      display: 'none',
    },
  },
  contentWrapper: {
    margin: '30px auto',
    maxWidth: `${CONTENT_WIDTH}px`,
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      maxWidth: '98%',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      width: '100%',
      margin: '0',
      maxWidth: 'none',
    },
  },
  cardGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 'auto',
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      display: 'block',
    },
  },
  mainColumn: {
    width: `${MAIN_COL_WIDTH}px`,
    height: `${CONTENT_HEIGHT}px`,
    zIndex: '1',
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      minWidth: '290px',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      width: MOBILE_CONTENT_WIDTH + '% !important',
      maxWidth: '625px',
      height: '270px',
      margin: '15px auto',
      marginBottom: '0',
    },
  },
  trackingColumn: {
    width: `${SECONDARY_COL_WIDTH}px`,
    height: `${CONTENT_HEIGHT}px`,
    margin: '0px 0px 0px 0px',
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      borderLeftStyle: 'none',
    },
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      maxWidth: '625px',
      minWidth: '400px',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      width: MOBILE_CONTENT_WIDTH + '% !important',
      minWidth: '290px',
      height: 'auto',
      margin: '15px auto',
      marginTop: '-1px',
    },
  },
  secondaryColumn: {
    width: `${THIRD_COL_WIDTH}px`,
    margin: '0px 0px 0px 20px',
    height: 'auto',
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      minWidth: '270px',
    },
    [theme.breakpoints.down(MIN_DESKTOP_SIZE + 280)]: {
      width: '100%',
      margin: '30px auto',
    },
  },
  smsAndPromoDesktop: {
    width: `${THIRD_COL_WIDTH}px`,
    margin: '0px 0px 0px 20px',
    height: 'auto',
    [theme.breakpoints.down(MIN_DESKTOP_SIZE + 280)]: {
      display: 'none',
    },
  },
  smsAndPromoBelowDesktop: {
    width: '100%',
    margin: '30px auto',
    height: 'auto',
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      minWidth: '270px',
    },
    [theme.breakpoints.up(MIN_DESKTOP_SIZE + 280)]: {
      display: 'none',
    },
  },
  smsCard: {
    width: '100%',
    marginBottom: `${COL_SPACING}px`,
    [theme.breakpoints.up(MIN_DESKTOP_SIZE + 280)]: {
      height: '185px',
    },
    [theme.breakpoints.between(MIN_TABLET_SIZE, MIN_DESKTOP_SIZE + 280)]: {
      margin: 'auto',
      height: '90px',
    },
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      margin: '15px auto',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      maxWidth: '625px',
      minHeight: '120px',
      margin: '10px auto !important',
      width: MOBILE_CONTENT_WIDTH + '% !important',
    },
  },
  promoImageWrapper: {
    width: '100%',
    maxWidth: '320px',
    margin: '30px auto',
  },
  canvas: {
    borderRadius: '10px',
  },
  giftWrapPanel: {
    display: 'block',
    borderRadius: '7px',
    border: '0.5px solid #DFE3E8',
    boxShadow: '0 1px 10 rgb (0, 0, 0, 0.05)',
    position: 'relative',
    marginBottom: '30px',
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      width: '95%',
      maxWidth: '625px',
      margin: '30px auto',
    },
  },
}))

export default function TrackingPage() {
  const promoImage = usePromoImage()

  const promoMaxHeight = promoImage?.size_type === PROMO_IMAGE_TALL_TYPE ? CONTENT_HEIGHT : SHORT_PROMO_IMAGE_HEIGHT

  const classes = useTrackingPageStyles()
  const promoClasses = usePromoStyles()

  return (
    <div className={classes.contentWrapper}>
      <GiftWrapPanel className={classes.giftWrapPanel} />
      <div className={classes.cardGrid}>
        <div className={classes.mainColumn}>
          <MainCard />
        </div>
        <div className={classes.trackingColumn}>
          <TrackingCard />
        </div>
        {!!promoImage && (
          <div className={classes.smsAndPromoDesktop}>
            <SmsCard className={classes.smsCard} />
            <div className={classes.promoImageWrapper}>
              <PromoImage maxHeight={promoMaxHeight} classes={promoClasses} />
            </div>
          </div>
        )}
      </div>
      <div className={classes.smsAndPromoBelowDesktop}>
        <SmsCard className={classes.smsCard} />
        <ShipmentActivity className={classes.shipmentActivity} />
        {!!promoImage && (
          <div className={classes.promoImageWrapper}>
            <PromoImage maxHeight={promoMaxHeight} classes={promoClasses} />
          </div>
        )}
      </div>
    </div>
  )
}
