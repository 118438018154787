import React from 'react'
import { string, array, shape, bool } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Icon(props: any) {
  return <FontAwesomeIcon {...props} />
}

Icon.propTypes = {
  icon: shape({
    prefix: string.isRequired,
    iconName: string.isRequired,
    icon: array.isRequired,
  }).isRequired,
  size: string,
  spin: bool,
  className: string,
}

Icon.defaultProps = {
  className: '',
}
