import React, { useEffect, useRef, useState } from 'react'

import { useBrandingData, useFeatureEnables } from '../dataProviders'
import { makeBtStyles } from '../Theme'

import { BtLink } from './BtLink'

const usePromoStyles = makeBtStyles(theme => ({
  root: {
    display: 'block',
  },
  img: {
    display: 'block',
    width: '100%',
    maxHeight: '100%',
  },
  canvas: {
    width: '100%',
  },
}))

export const usePromoImage = () => {
  const { promo_images } = useBrandingData()
  return promo_images?.main?.images && promo_images.main.images[0]
}

export default function PromoImage({ maxHeight, classes = {} }: { maxHeight: number; classes?: { canvas?: string } }) {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const defaultClasses = usePromoStyles()
  const promoImage = usePromoImage()
  const [image, setImage] = useState<any>()
  const { usePromoImageLinks } = useFeatureEnables()

  useEffect(() => {
    const { x: cropX, y: cropY, width: cropWidth, height: cropHeight } = promoImage?.crop || {}
    const canvas = canvasRef?.current

    if (canvas && image) {
      const srcX = cropX || 0
      const srcY = cropY || 0

      const srcWidth = cropWidth || image?.width
      const srcHeight = cropHeight || image?.height

      // Stretch width-wise to container, then let the height be whatever it scales to but not taller than maxHeight.
      // Meaning we might "squish" the image vertically if it is (or was cropped to be) too tall for the spot it's in.
      const width = canvas.clientWidth
      const destWidth = width
      const destHeight = Math.min((srcHeight * width) / srcWidth, maxHeight)

      canvas.width = destWidth
      canvas.height = destHeight

      const context = canvas.getContext('2d')
      context?.drawImage(image.image, srcX, srcY, srcWidth, srcHeight, 0, 0, destWidth, destHeight)
    }
  }, [promoImage, image, canvasRef, maxHeight])

  useEffect(() => {
    if (promoImage) {
      const srcImage = new Image()
      srcImage.onload = () =>
        setImage({ image: srcImage, width: srcImage.naturalWidth, height: srcImage.naturalHeight })
      srcImage.src = promoImage?.image_url
    }
  }, [promoImage])

  if (!promoImage) {
    return null
  }

  const theImage = <canvas ref={canvasRef} role="img" className={`${defaultClasses.canvas} ${classes.canvas}`} />

  return promoImage.link_url && usePromoImageLinks ? (
    <BtLink
      href={promoImage.link_url}
      className={defaultClasses.root}
      target="_blank"
      aria-label={promoImage.title_text}>
      {theImage}
    </BtLink>
  ) : (
    <div className={defaultClasses.root} aria-label={promoImage.title_text}>
      {theImage}
    </div>
  )
}
